<template id="inventario">
<div class="page-inventario">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Sugerencia para compras" class="elevation-1 px-5 py-3">

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{ props.row.codigo_barras.join(", ") }}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria.length > 0 ? props.row.categoria[0] : ""}}
                </template>

                <template slot="actions" slot-scope="props">
                <v-btn x-small fab dark color="green" @click.native="get_detalle(props.row._id)" title="Ver Detalle" v-tippy>
                      <v-icon>list</v-icon>
                    </v-btn>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modal_detalle" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Artículos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col>
              <v-client-table ref="vuetablearticulos" :data="lote_detalle" :columns="columns_detalle" :options="options_detalle" class="elevation-2 pa-2">
                <template slot="id_sucursal" slot-scope="props">
                  {{get_sucursal(props.row.id_sucursal)}}
                </template>

                <template slot="fecha_caducidad" slot-scope="props">
                  {{props.row.fecha_caducidad.toString().substring(0,10)}}
                </template>

                <template slot="fecha_entrada" slot-scope="props">
                  {{props.row.fecha_entrada.toString().substring(0,10)}}
                </template>

                <template slot="cantidad" slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.cantidad}}
                  </div>
                </template>

                <template slot="precio_compra" slot-scope="props">
                  <div style="text-align: center;">
                    ${{props.row.precio_compra}}
                  </div>
                </template>

                <template slot="actual" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.actual).toFixed(2)}}
                  </div>
                </template>

                <template slot="comprometido" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.comprometido).toFixed(2)}}
                  </div>
                </template>

              </v-client-table>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('catalogos.articulos')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {},
  data() {
    return {
      modal_detalle: false,
      lote_detalle: [],
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Actual",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "codigo_barras",
        "nombre",
        "categoria",
        "marca",
        "total_inventario",
         "minimo",
        "maximo",
        "reorden", "porpedir",
        "actions"
      ],
      columns_detalle: ["id_sucursal", "cantidad", "precio_compra", "fecha_caducidad", "fecha_entrada", "actual",  "comprometido"],
      options_detalle: {
        filterable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],
        sortable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],

        headings: {
          id_sucursal: "Sucursal",
          cantidad: "Cantidad",
          precio_compra: "Precio de Compra",
          fecha_caducidad: "Fecha de Caducidad",
          fecha_entrada: "Fecha de Entrada",
          actual: "Actual",

          comprometido: "Comprometido"
        },
      },
      options: {
        filterable: ["codigo_barras", "nombre", "categoria", "marca"],
        sortable: ["codigo_barras", "nombre", "categoria", "marca"],

        headings: {
          codigo_barras: "Código de Barras",
          nombre: "Nombre",
          categoria: "Categoría",
          "marca": "Marca/Modelo",
          total_inventario: "Total",
          minimo: "Minimo",
          maximo: "Máximo",
          reorden: "Reorden",
          porpedir: "Por Pedir",
          actions: "Operaciones"
        },
      },
      modal: false,
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      let self= this;
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"articulos"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(async response => {
          if (response.data.docs.length) {
            let procesado = await this.procesar_articulos(response.data.docs);
            this.items = this.items.concat(procesado);
            this.props_paginacion.total_registros = this.items.length;


            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    procesar_articulos: async function(datos) {
       let self= this;
      let ids = [];
      let sucursal = this.$local_storage.get("sb_sucursal", "");
      datos.forEach(async x => {
        ids.push(x._id);
        x.total_inventario = 0;
        let datos_consulta = {
          "id_articulo": x._id,
          "id_sucursal": sucursal == 0 ? "" : sucursal
        };
         if(x.stock != undefined){
            if (x.stock[self.$local_storage.get("sb_sucursal")] != undefined)
            {
              x.minimo = x.stock[self.$local_storage.get("sb_sucursal")].minimo;
              x.maximo = x.stock[self.$local_storage.get("sb_sucursal")].maximo;
              x.reorden = x.stock[self.$local_storage.get("sb_sucursal")].reorden;
            }
          }
          else{
              x.minimo = 0;
              x.maximo = 0;
              x.reorden = 0;
          }
         await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
          if (result.length > 0) {
            result.forEach(el => {
              x.total_inventario += el.existencia
            });
          } else {
            x.total_inventario += 0;
          }
          if(x.total_inventario <= x.reorden ){
            x.porpedir = x.maximo - x.total_inventario;
          }else{
            x.porpedir = 0;
          }

        }).catch(err => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al consultar existencia de artículo.",
            footer: err
          });
        });
      });

      return datos;
    },
    get_detalle: function(id_articulo) {
      let datos_consulta = {
        "type":"lotes",
        "id_articulo": id_articulo,
        "actual": {
          "$gt": 0
        }
      };
      if (this.$local_storage.get("sb_sucursal", "") != 0) {
        datos_consulta.id_sucursal = this.$local_storage.get("sb_sucursal", "");
      }

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: datos_consulta
          },
        }).then(async response => {
          if (response.data.docs.length) {
            this.lote_detalle = response.data.docs;
            this.modal_detalle = true;
          } else {
            this.lote_detalle = [];
            this.modal_detalle = true;
          }
        })
        .catch(error => {
          this.modal_detalle = false;
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al consultar el detalle del artículo.",
            footer: err
          });
        })
    },
    get_sucursal: function(id) {
      let seleccionada = window.sucursales.filter(x => x._id == id);

      return seleccionada.length > 0 ? seleccionada[0].nombre : "No Encontrada";
    }
  }
}
</script>
